import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MeetingDetails,CaseData } from '../_models/mcst-model';


@Injectable({ providedIn: 'root' })
export class PrivateService {

    constructor(private http: HttpClient) { }

    submitCaseForm(formdata: FormData): Observable<HttpEvent<{}>> {
        const req = new HttpRequest('POST', environment.apiUrl + 'api/v1/offices/mediation/submit-request', formdata, {
          reportProgress: true,
          responseType: 'text'
        });
        return this.http.request(req);
      }

      submitPrivateCaseForm(formdata: FormData): Observable<HttpEvent<{}>> {
        const req = new HttpRequest('POST', environment.apiUrl + 'api/v1/internal/offices/mediation/submit-request', formdata, {
          reportProgress: true,
          responseType: 'text'
        });
        return this.http.request(req);
      }

      editCaseForm(office,caseId,formdata): Observable<HttpEvent<{}>> {
        const req = new HttpRequest('PUT', environment.apiUrl + 'api/v1/internal/offices/'+office+'/cases/'+caseId, formdata, {
          reportProgress: true,
          responseType: 'text'
        });
        return this.http.request(req);
      }  

    submitIndautorMeeting(formdata: FormData,type): Observable<HttpEvent<{}>> {
        const req = new HttpRequest('POST', environment.apiUrl + 'api/v1/internal/meetings/create-meeting/' + type , formdata, {
            reportProgress: true,
            responseType: 'text'
        });
        return this.http.request(req);
    }

 

    getMeetingDetails(type): Observable<MeetingDetails[]> {
        return this.http.get<MeetingDetails[]>(environment.apiUrl  +'api/v1/internal/meetings/' + type);
    }


    getOfficePermissionDetails(): Observable<any[]> {
        return this.http.get<any[]>(environment.apiUrl + 'api/v1/internal/offices/permissions');
    }

    getOfficeConfiguration(office): Observable<any[]> {
        return this.http.get<any[]>(environment.apiUrl + 'api/v1/offices/configuration?office=' + office);
    }

    getAllcases(office): Observable<any[]> {
        return this.http.get<any[]>(environment.apiUrl  +'api/v1/internal/offices/'+office+'/cases');
    }

    getCases(office,status): Observable<any[]> {
      return this.http.get<any[]>(environment.apiUrl  +'api/v1/internal/offices/'+office+'/cases?caseStatus='+status);
    }

    getAllCases(office): Observable<any[]> {
      return this.http.get<any[]>(environment.apiUrl  +'api/v1/internal/offices/'+office+'/cases?caseStatus=ACTIVE&caseStatus=CLOSED');
    }

    getAllPrecases(office): Observable<any[]> {
      return this.http.get<any[]>(environment.apiUrl  +'api/v1/internal/offices/'+office+'/cases?caseStatus=PENDING_APPROVAL&caseStatus=REJECTED');
    }

    getAllCasesDetailsWithStatus(office,status): Observable<any[]> {
      return this.http.get<any[]>(environment.apiUrl  +'api/v1/internal/offices/'+office+'/cases-with-all-data?caseStatus='+status);
    }

    getAllCasesForReports(office): Observable<any[]> { 
       return this.http.get<any[]>(environment.apiUrl  +'api/v1/internal/offices/'+office+'/cases-with-all-data?caseStatus=ACTIVE&caseStatus=CLOSED'); 
    }
    getAllPreCasesForReports(office): Observable<any[]> {
      return this.http.get<any[]>(environment.apiUrl  +'api/v1/internal/offices/'+office+'/cases-with-all-data?caseStatus=PENDING_APPROVAL&caseStatus=REJECTED');
    }

    getCaseDetails(office,id): Observable<CaseData[]> {
        return this.http.get<CaseData[]>(environment.apiUrl  +'api/v1/internal/offices/'+office+'/cases/'+id);
    }


    updateCaseStatus(office,id,data:FormData): Observable<HttpEvent<{}>> {
        const req = new HttpRequest('PUT', environment.apiUrl + 'api/v1/internal/offices/'+office+'/cases/'+id+'/status', data, {
          reportProgress: true,
          responseType: 'text'
        });
        return this.http.request(req);
      }


}


