import { Component, HostListener, OnInit, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './_services/language.service';
import { WipoAuthService } from './_services/wipo-auth.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { PrivateService } from './_services/private.service';
import { officeConfiguration } from './_models/case-configuration';
import { UtilsService } from './_shared/UtilsService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  AMC_LANGUAGE_COOKIE = 'amc_language'; // WIPO language standard cookie name
  AMC_FORMS_APP_ID = 'app-0037'
  languageList = '[{ "code": "en"}, {"code": "es"}]';
  navbarTitle = '';
  appLink = '';
  pageKey = '';
  appCategory = '';
  contactUsOptions = new Array<any>();
  DCTIBreadcrumb = new Array<any>();
  sticky = false;
  private renderer: Renderer2;
  pageData: any;
  languages: any[];
  langSelected = false;
  permission = true;

  linkOfficesCases = '';
  linkOfficesMeetings = '';
  linkOfficesCasesStyle = '';
  linkOfficesMeetingsStyle = '';

  OfficesCases = '/offices/private/cases';
  OfficesMeetings = '/offices/private/meetings';

  adrMediation = '/adr/mediation';
  adruniteralL = '/adr/unilateral-mediation';
  adrarbitration = '/adr/arbitration';
  adrexpedited = '/adr/expedited-arbitration';
  adrgoodOffice = '/adr/good-offices-services';

  linkadrMediation = '';
  linkadruniteralL = '';
  linkadrarbitration = '';
  linkadrexpedited = '';
  linkadrgoodOffice = '';

  rokMCST = '/rok/mcst';
  MediatioL = '/rok/mcst/mediation';
  uniteralL = '/rok/mcst/unilateral-mediation';
  goodOffice = '/rok/mcst/good-offices-services';

  linkrokMCST = '';
  linkMediation = '';
  linkUnilateral = '';
  linkgoodOffices = '';
  langue = '';
  officeDetails = [];

  currentRoute: string;
  officesParam: string = '';
  officeName = '';
  officeConfig = '';
  navbarAppname: string = '';
  caseLoad = false;
  officeLoad = false;

  officeCaseLanguage = [];
  privateOfficeLanguage = [];
  publicConfig: officeConfiguration[];
  OfficeValidName = false;
  DCITMainItemName = '';
  paramsLanguage = 'en';


  

  @HostListener('window:scroll', []) onWindowScroll() {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.sticky = verticalOffset > 50;
  }

  constructor(private translate: TranslateService, public languageService: LanguageService, 
    private service: PrivateService, private activeRoute: ActivatedRoute,private utilsService: UtilsService,
    private rendererFactory: RendererFactory2, private wipoAuthService: WipoAuthService, private cookieService: CookieService, private router: Router) {

    this.currentRoute = "";
    this.router.events.subscribe(async(event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        if(event.url.includes('/office/private')){
          let arrURL = event.url.split("/");
          this.paramsLanguage = arrURL[1];
          this.officeName = arrURL[4]?.toLowerCase();
          this.OfficeValidName = await this.verifyExistingOffice();
          this.languageService.currentLanguage = this.paramsLanguage;
        } 
        this.setPageKey(this.currentRoute);
        this.setAppLink()
        this.setBreadcrumb();
        this.setTitle();
        this.setContactUsOptions();
      }

    });

    this.renderer = this.rendererFactory.createRenderer(null, null);

  }
  async ngOnInit() {
    this.languageService.currentLanguage = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);
    if(this.languageService.currentLanguage === undefined) {   this.languageService.currentLanguage = this.wipoAuthService.preferedLangague.toLowerCase(); }

    await this.assignLanguage();

    console.log('this.languageService.currentLanguage -----',this.languageService.currentLanguage)

    this.languageService.changeLanguage();
    this.updateWipoLanguageCookie(this.languageService.currentLanguage);

    this.appLink = environment.siteUrl + this.languageService.currentLanguage;

    this.loadLink(); 

    // set initial language
    this.languageService.initialLanguageSetup();

    this.renderLanguage()

  }

// you can copy the code from here
verifyExistingOffice(): Promise<boolean> {
  return new Promise<boolean>(async (resolve) => {
    if (!this.officeName) {
      this.officeLoad = true;
      resolve(false);
    } else {
      const response = await this.service.getOfficePermissionDetails().toPromise();
        if(response) {
        this.officeDetails = response;
        const foundOffice = this.officeDetails.find(element => element?.code.toLowerCase() === this.officeName);
                if (!foundOffice) {
                  this.router.navigate(['page-not-found']);
                  resolve(false);
                } else {
                const languages = foundOffice.languages.split(",");
                const hasMultipleLanguages = languages.length > 1;
                this.privateOfficeLanguage = [];
                if (hasMultipleLanguages) {
                  this.utilsService.redirctURLperlanguage(languages, this.paramsLanguage);
                  languages.forEach(val => {
                    this.privateOfficeLanguage.push({ code: val.toLowerCase() });
                  });
                } else {
                  const selectLanguage = languages[0];
                  // you may want to add logic here to handle the case where there is only one language
                  this.utilsService.redirctURLperlanguage(languages, this.paramsLanguage);
                }
                this.officeLoad = true;
                resolve(true);
              }
     }
    }
  });
}

  private updateWipoLanguageCookie(language: string) {
    this.cookieService.delete(this.AMC_LANGUAGE_COOKIE);
    this.cookieService.set(this.AMC_LANGUAGE_COOKIE, language, 0, '/');
  }

  private _initAppLink(language: string) {

    this.linkOfficesCases = language + this.OfficesCases;
    this.linkOfficesMeetings = language + this.OfficesMeetings;

    this.linkadrMediation = language + this.adrMediation;
    this.linkadruniteralL = language + this.adruniteralL;
    this.linkadrarbitration = language + this.adrarbitration;
    this.linkadrexpedited = language + this.adrexpedited;
    this.linkadrgoodOffice = language + this.adrgoodOffice;

    this.linkrokMCST = language + this.rokMCST;
    this.linkMediation = language + this.MediatioL;
    this.linkUnilateral = language + this.uniteralL;
    this.linkgoodOffices = language + this.goodOffice;

    this.linkOfficesCases = language + this.OfficesCases;
    this.setAppLink()

  }


  doUrlLanguage(language: string) {
    this.translate.use(language);
    this.langSelected = true;
    let baseUrl = "";
    let arrUrl = [];
    if (this.router.url.indexOf('?') >= 0) {
      arrUrl = this.router.url.split('?');
      let index = this.router.url.indexOf('?');
      baseUrl = this.router.url.substring(3, index);
      let localizedUrl = '/' + language + baseUrl;
      this.router.navigate([localizedUrl], { queryParamsHandling: 'preserve' });
      if (language === 'en') {
        if (arrUrl[1])
          window.location.href = window.location.origin + '/amc-forms' + localizedUrl + '?' + arrUrl[1];
        else
          window.location.href = window.location.origin + '/amc-forms' + localizedUrl;
      }
    } else {
      baseUrl = this.router.url.substring(3);
      let localizedUrl = '/' + language + baseUrl;
      this.router.navigate([localizedUrl]);
      if (language === 'en')
        window.location.href = window.location.origin + '/amc-forms' + localizedUrl;
    }
  }


  async setTitle() {
    if (this.isROKMCST()) {
      this.translate.get('navbar.isMCSTappName').subscribe((translated: string) => {
        this.navbarTitle = translated;
      });
    } else if (this.isDomain()) {
      this.translate.get('navbar.isDomainappName').subscribe((translated: string) => {
        this.navbarTitle = translated;
      });
    } else if (this.isPrivate()) {
      if (this.OfficeValidName) {
        this.navbarAppname = 'office.' + this.officeName + '.private.appName';
      }
    } else {
      this.translate.get('navbar.isADRappName').subscribe((translated: string) => {
        if(translated === 'navbar.isADRappName')
        this.navbarTitle = '';
        else
        this.navbarTitle = translated;
      });
    }
  }

  setAppLink() {
    if (this.isADR()) {
      this.appLink = window.location.origin + '/amc-forms/adr/';
    } else if (this.isADRYoung()) {
      this.appLink = window.location.origin + '/amc-forms/adryoung/registration';
    } else if (this.isROKMCST()) {
      this.appLink = window.location.origin + '/amc-forms/rok/mcst';
    } else if (this.isDomain()) {
      this.appLink = window.location.origin + '/amc-forms/en/domains/';
    } else if (this.isPrivate()) {
      this.appLink = window.location.origin + '/amc-forms/en/office/private';
    } else {
      this.appLink = window.location.origin + '/amc-forms/';
    }
  }

  setPageKey(url) {
    if (url.includes('/dcti')) {
      this.pageKey = this.translate.instant('DCTI.breadcrumbTitle');
    }
    else if (url.includes('/mediation')) {
      this.pageKey = 'mediation';
    }
    else if (url.includes('/unilateral-mediation')) {
      this.pageKey = 'unilateral-mediation';
    }
    else if (url.includes('/good-offices-services')) {
      this.pageKey = 'good-offices-services';
    }
    else if (url.includes('/arbitration')) {
      this.pageKey = 'arbitration';
    }
    else if (url.includes('/expedited-arbitration')) {
      this.pageKey = 'expedited-arbitration';
    } else {
      this.pageKey = 'amc-forms';
    }

  }


  setBreadcrumb() {
    this.translate.get('DCTI.breadcrumbTitle').subscribe((translated: string) => {
      this.DCITMainItemName = translated;
      this.DCTIBreadcrumb= [{
        itemName: this.DCITMainItemName,
        itemURL: ""
     }];
    });

  }

  setContactUsOptions() {
    this.contactUsOptions = [];
    this.contactUsOptions.push({
      code: 'contact',
      link: 'https://www3.wipo.int/contact/'+this.languageService.currentLanguage+'/area.jsp?area=arbitration'
    });
  }

  setApplicationCategory() {
    if (this.isADR() || this.isADRYoung()) {
      this.appCategory = 'ip-adr';
    } else {
      this.appCategory = 'domain-dr';
    }
  }

  isDCTI() {
    if (window.location.href.includes('/dcti')) {

       return true;
    }
    return false;
  }

  isCase() {
    if (window.location.href.includes('/case')) {
       return true;
    }
    return false;
  }

  isADR() {
    if (window.location.href.includes('/adr') || window.location.href.includes('/wipo-mcst')) {
      return true;
    }
    return false;
  }

  isADRYoung() {
    if (window.location.href.includes('/adryoung')) {
      return true;
    }
    return false;
  }

  isROKMCST() {
    if (window.location.href.includes('/rok')) {
      return true;
    }
    return false;
  }

  isPrivate() {
    if (window.location.href.includes('/office/private')) {
      return true;
    }
    return false;
  }

  isDomain() {
    if (window.location.href.includes('/domains')) {
      return true;
    }
    return false;
  }

  isPageNotFound() {
    if (window.location.href.includes('page-not-found')) {
      return true;
    }
    return false;
  }


  

navigateCases(){
  this.router.navigate([this.linkOfficesCases], { queryParams: { office: this.officeName } });
}

navigateMeetings(){
  this.router.navigate([this.linkOfficesMeetings], { queryParams: { office: this.officeName } });
}

loadLink() {
  this.linkOfficesMeetings = this.languageService.currentLanguage + this.OfficesMeetings;
  this.linkOfficesCases = this.languageService.currentLanguage + this.OfficesCases;
  this.linkadrMediation = this.languageService.currentLanguage + this.adrMediation;
  this.linkadruniteralL = this.languageService.currentLanguage + this.adruniteralL;
  this.linkadrarbitration = this.languageService.currentLanguage + this.adrarbitration;
  this.linkadrexpedited = this.languageService.currentLanguage + this.adrexpedited;
  this.linkadrgoodOffice = this.languageService.currentLanguage + this.adrgoodOffice;
  this.linkrokMCST = this.languageService.currentLanguage + this.rokMCST;
  this.linkMediation = this.languageService.currentLanguage + this.MediatioL;
  this.linkUnilateral = this.languageService.currentLanguage + this.uniteralL;
  this.linkgoodOffices = this.languageService.currentLanguage + this.goodOffice;

}


renderLanguage() {
   // Event generated by WIPO-navbar when language get changed
   this.renderer.listen(document, 'wipoLanguageChange', e => {
    console.log('amc-navbar', 'wipoLanguageChange', e.detail.languageSelected);
    if (this.languageService.isLanguageSupported(e.detail.languageSelected)) {
      this.languageService.currentLanguage = e.detail.languageSelected;
      this.languageService.changeLanguage();
      this._initAppLink(e.detail.languageSelected);
      this.updateWipoLanguageCookie(e.detail.languageSelected);
      this.doUrlLanguage(e.detail.languageSelected)
      this.setTitle();
    }
    this.renderer.listen(document, 'wipoLoginClick', e => {
      console.log('wipo-navbar', 'wipoLoginClick', e.detail.isUserLoggedIn);
      this.wipoAuthService.loginWithUserPassword();
    });
    this.renderer.listen(document, 'wipoLogoutClick', e => {
      console.log('wipo-navbar', 'wipoLogoutClick', e.detail.isUserLoggedIn);
      this.wipoAuthService.logout();
    });
  });
}

async assignLanguage() {
  if (window.location.href.includes('page-not-found')) {
    if (this.cookieService.get(this.AMC_LANGUAGE_COOKIE)) {
      this.languageService.currentLanguage = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);
    } else { this.languageService.currentLanguage = 'en'; }
  }
  else if (window.location.href.includes('/en')) {
    this.languageService.currentLanguage = 'en';
  }
  else if (this.isROKMCST()) {
    if (window.location.href.includes('/en')) {
      this.languageService.currentLanguage = 'en';
    } else {
      this.languageService.currentLanguage = 'ko';
    }
  }
  else if (this.isPrivate() || this.isDCTI() || this.isCase()) { 
    if (window.location.href.includes('/es')) { 
      this.languageService.currentLanguage = 'es';
    }
    if (window.location.href.includes('/pt')) {
      this.languageService.currentLanguage = 'pt';
    }
    else if (window.location.href.includes('/fr')) {
      this.languageService.currentLanguage = 'fr';
    }
    else if (window.location.href.includes('/ar')) {
      this.languageService.currentLanguage = 'ar';
    }
    else if (window.location.href.includes('/sw')) {
      this.languageService.currentLanguage = 'sw';
    }
  }
  else if (this.isADR() || this.isADRYoung()) {
      if (window.location.href.includes('/ar/')) {
        this.languageService.currentLanguage = 'ar';
      } else if (window.location.href.includes('/es')) {
        this.languageService.currentLanguage = 'es';
      } else if (window.location.href.includes('/fr')) {
        this.languageService.currentLanguage = 'fr';
      } else if (window.location.href.includes('/ru')) {
        this.languageService.currentLanguage = 'ru';
      } else if (window.location.href.includes('/zh')) {
        this.languageService.currentLanguage = 'zh';
      } else {
        this.languageService.currentLanguage = 'en';
      }
    }
  else if (this.isDomain()) {
      this.languageService.currentLanguage = 'en';
    }

    console.log('this.languageService.currentLanguage ----',this.languageService.currentLanguage)
}




}
